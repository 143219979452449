import React from 'react';
import './App.css';
import Education from './Education';
import GridOfCards from './GridOfCards'
import { Container, Row, Col } from 'react-bootstrap';


function App() {
  // const projectColors = [
  //   '#0cead9',
  //   '#3acadf',
  //   '#729efd',
  //   '#8a64d6',
  //   '#5c3a92'
  // ];
  const projectColors = [
    '#ff7676',
    '#ff8977',
    '#fea879'
  ];
  const projects = {
    dance : projectColors[0],
    movie : projectColors[1],
    musicVideo : projectColors[2]
  }
  
  const projectCards = [
      { title: 'La Isla - Agrupacion Senor Serrano', text: '2024',  color : projects.dance },
      { title: 'Chez Michou, The Mystery of Blue', text: '2024 - TRAFIK/ Žak Valenta',  color : projects.dance },
      { title: 'Do You Want To Produce me ?', text: '2024 - Jessica D\'Angelo & Toni Flego',  color : projects.dance },
      { title: 'Dancing Together, Again', text: '2023 - 2024',  color : projects.dance },
      { title: 'European Project Dancing Again', text: 'Georgia, Tibisi - 2023',  color : projects.dance },
      { title: 'Beyond Front Dance', text: 'DCL Krakow Dance Theater - 2023',  color : projects.dance },
      { title: 'Žal (Movie by Mara Prpić)', text: '2023',  color : projects.movie },
      { title: 'From Off to On ', text: '2023 - Iole La Sala & Toni Flego',  color : projects.dance },
      { title: 'No More Birds On my Side', text: '2023 - Oriantheatre',  color : projects.dance },
      { title: 'How Can I Protect Myself From All This ?', text: '2023 - Toni Flego x Accademia Nazionale Di Danza Roma',  color : projects.dance },
      { title: 'Dear Friend, dream, breathe and move to think', text: '2022 - Toni Flego',  color : projects.dance},
      { title: 'Songs: Regaining Lost Time', text: '2022 - Anna Kosiorowska',  color : projects.dance},
      { title: 'FAR', text: '2021 - Wayne McGregor',  color : projects.dance},
      { title: 'Nemesis Vs Anubis Vs Everyone', text: '2021 - Mounir Saeed',  color : projects.dance},
      { title: 'I am Alive', text: '2021 - TRAFIK',  color : projects.dance},
      { title: 'Jonathan - Falling (Music video)', text: '2021',  color : projects.musicVideo },
      { title: 'Atzur - Genesis (Music video)', text: '2021',  color : projects.musicVideo },
      { title: 'In Museum', text: '2020 - Marie Chouinard',  color : projects.dance},
      { title: 'Le Sacre du pritemps', text: '2020 - Xavier LeRoy',  color : projects.dance},
      { title: 'The Mushroom at the End of the World', text: '2020 - Marco D’Agostin',  color : projects.dance},
      { title: 'EPK Port of diversity (Dalibor Matanic)', text: '2019',  color : projects.musicVideo},
      { title: 'Trinacria', text: '2019 - E.sperimenti Dance Company',  color : projects.dance},
      { title: 'Oxymoron', text: '2019 - Toni Flego',  color : projects.dance},
      { title: 'Off.beat.lab', text: '2018', color : projects.dance },
      { title: 'Nipplepeople - Nikada (Music video)', text: '2018',  color : projects.musicVideo },
      { title: 'Trittico d’autore', text: '2018 - (Sharon Fridman, Valerio Longo, Paolo Mohovic)',  color : projects.dance },
      { title: 'Hopera', text: '2018 - E.sperimenti Dance Company',  color : projects.dance },
      { title: 'InTrans', text: '2018 - Toni Flego & Ivona Medić',  color : projects.dance },
      { title: 'Quanto Basta', text: '2018 - E.sperimenti Dance Company',  color : projects.dance },
      { title: 'Halab', text: '2018 - Sol Pico',  color : projects.dance },
      { title: 'Woe From Wit', text: '2018 - Sona Hovsepyan',  color : projects.dance },
      { title: 'Travellogue', text: '2017 - TRAFIK', color : projects.dance },
      { title: 'IGOWEGOYOU !?', text: '2016 - (Thierry Smits)', color : projects.dance },
      { title: 'Summer Collection by M.F.', text: '2016 - Orientheater dance company', color : projects.dance },
      { title: 'Faun', text: '2016 - Marina Granić & Toni Flego', color : projects.dance },
      { title: 'Weltschmertz', text: '2015 - TRAFIK', color : projects.dance },

      // Add more cards as needed
    ];
  
  const awardCards = [
    {title:"2nd place Premio Roma - Choreography", text: '2022', color : '#f0bf60', img : "/Slike/award.svg"},
    {title:"Grand Prix for performance - I'M ALIVE", text: 'Georgia, Tibisi - International dance festival 2022', color : '#f0bf60', img : "/Slike/award.svg"},
    {title:"Winner Choreographer Resid'AND", text: 'Academy of Dance, Roma - 2022', color : '#f0bf60', img : "/Slike/award.svg"},
    {title: "La Sfera Danza", text: 'Italian national award for contemporary dance and performance  - 2020', color : '#f0bf60', img : "/Slike/award.svg"},
    {title:"3rd place Premio Roma", text: '2019', color : '#f0bf60', img : "/Slike/award.svg"},
    {title: "Special prize Premio Roma", text: '2019', color : '#f0bf60', img : "/Slike/award.svg"},
    {title: "Red Dance Grand Prix award", text: 'China, Bejing - 2019', color : '#f0bf60', img : "/Slike/award.svg"},
  ];

  const FilterData = (project) =>{
    if(project == projects.musicVideo){
      return projectCards.filter((element)=> element.color === project || element.color == projects.movie);
    }
    if(project == projects.movie){
      return projectCards.filter((element)=> element.color === project || element.color == projects.musicVideo);
    }

    return projectCards.filter((element)=> element.color === project);
  };


  return (
    <div className='scrollContainer'>
      <div className="bg first">
        <div className="container allign_elements_center">
          <img src={process.env.PUBLIC_URL + '/Tekst/ToniFlego.png'} className='opacity_width' />
        </div>
      </div>

      <div className="bg second">
        <div className="container allign_elements_center">
        <img src={process.env.PUBLIC_URL + '/Tekst/moveToThink.png'} className='opacity_width' />
        </div>
      </div>

      <div className="bg scroll">
        <div className="container-fluid">
        <div className="row align-items-center vh-100">
          {/* Left Column */}
          <div className="col-md-6 text-center text-md-start p-4">
            <p className='who-am-i-txt'>
             I am Toni, and I move to awaken thought and embrace sensation, flowing with the symphony
             of shades and emotions that paint the world. <br/><br/> Through this journey, I find joy in discovering the 
             myriad charachters that dwell within me, each a vibrant spirit waiting to come alive in the art of 
             performance.
            </p>
          </div>

          {/* Right Column */}
          <div className="col-md-6 text-center">
          <div className="video-wrapper">
            <video
              src="/Video/video2.mp4" // Replace with your video URL
              className="w-100"
              autoPlay
              loop
              muted
            ></video>
            </div>
          </div>
        </div>
      </div>
        {/*<Container className=''>
          <Row className="text-center">
            <Col>
            <p className="text-center" style={{color: 'white', fontSize: '18px' }}>
             I am Toni, and I move to awaken thought and embrace sensation,flowing with the symphony
             of shades and emotions that paint the world. Through this journey, I find joy in discovering the 
             myriad charachters that dwell within me, each a vibrant spirit waiting to come alive in the art of 
             performance.
            </p>
             </Col>
            <Col>
                <video autoPlay loop controls>
                  <source src="/Video/video1.mp4" type="video/mp4"/>
                </video>
            </Col>
          </Row>
        </Container>*/}
      </div>

      <div className="bg scroll">
        <Education />
      </div>

      {/* <div className='bg black'>
        <div className="container allign_elements_center">
          <video autoPlay loop controls>
            <source src="/Video/video1.mp4" type="video/mp4"/>
          </video>
        </div>
      </div> */}

      {/* <div className="bg black scroll">
        <Experience/>
      </div> */}

      <div className="bg fourth scroll">            
        <GridOfCards cards={FilterData(projects.dance)} title={"Shows and Projects"}/>
        <GridOfCards cards={FilterData(projects.musicVideo)} title={"Music Videos and Movies"}/>
        <GridOfCards cards={awardCards} title={"Awards"}/>
      </div>


      <div className="bg fifth">
        <div className="container h-100 d-flex flex-column">
          <div className='row flex-grow-1'>
           <div className='col'>
            <img src={process.env.PUBLIC_URL + '/Tekst/connect.png'} className='opacity_width' />
            </div>
          </div>
          <div className='row align-items-end flex-grow-1'>
            <div className='col-sm-3 ms-auto'>
              <a href="mailto:flego.tony@gmail.com">
              <img src={process.env.PUBLIC_URL + '/Tekst/mail.png'} className='img-responsive img-size right'/>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
