import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './GridOfCards.css';
import { Card as BootstrapCard } from 'react-bootstrap';

const Card = ({ title, text, color, img }) => {
  return (
    <BootstrapCard style={{background: color, color: 'white' }}>
      {img != null ? <BootstrapCard.Img variant="top" src={img} className='cardImg' /> : null}
      <BootstrapCard.Body>
        <BootstrapCard.Title>{title}</BootstrapCard.Title>
        <BootstrapCard.Text>
          {text}
        </BootstrapCard.Text>
      </BootstrapCard.Body>
    </BootstrapCard>
  );
}

const Grid = ({ cards }) => {
    return (
      <Container>
        <Row>
          {cards.map((card, index) => (
            <Col xs={12} sm={6} md={4} lg={3} key={index} className="mb-4">
              <Card title={card.title} text={card.text} color={card.color} img={card.img} />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }

function GridOfCards({cards, title}) {

    return(<div>
        <h1 className='title'>{title}</h1>
        <Grid cards={cards} />
        </div>
    );
}

export default GridOfCards;